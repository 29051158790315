import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import './index.scss'

export default function Contact(props) {
    if (props.resumeBasicInfo) {
      var sectionName = props.resumeBasicInfo.section_name.contact;
      var send_btn = props.resumeBasicInfo.form.send_button
      var name_placeholder = props.resumeBasicInfo.form.name_placeholder
      var email_placeholder = props.resumeBasicInfo.form.email_placeholder
      var message_placeholder = props.resumeBasicInfo.form.message_placeholder
    }

    const [inputs, setInputs] = useState({});
    const [location, setLocation] = useState('');

    //creating function to load ip address from the API
    const getData = async () => {
      const res = await axios.get('https://geolocation-db.com/json/')
      setLocation(res.data);
    }
    
    useEffect( () => {
      //passing getData method to the lifecycle method
      getData()
    }, [])

    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, [name]: value}))
    }
  
    const resetInputFields = () => {
      setInputs({ inputs: {} })
    }
    const handleSubmit = (event) => {
      const MySwal = withReactContent(Swal)
      axios.post(`/api/message`,
        {
          "city": location.city,
          "state": location.state,
          "country": location.country_name,
          "email": inputs.email,
          "ip": location.IPv4,
          "message": inputs.message.trim(),
          "name": inputs.name.trim(),
        }).then(res => {
          MySwal.fire({
            heightAuto: false,
            icon: 'success',
            title: 'Sent 👏',
            text: 'Your email has been sent!',
            timer: 3000,
            showConfirmButton: false
          })
        }).catch(error => {
          MySwal.fire({
            heightAuto: false,
            icon: 'error',
            title: 'Oops... 😬',
            text: 'Something went wrong, please try again later.',
            timer: 3000,
            showConfirmButton: false
          })
        })
      event.preventDefault();
      resetInputFields();
    }

    return (
      <section id="contact">
          <div className="col-md-12">
            <h1 style={{ color: "black" }}>
              <span>{sectionName}</span>
            </h1>
          </div>
      <div className="contact-form">  
        <form onSubmit={handleSubmit}>
          <ul>
            <li className="half">
              <input 
                placeholder={name_placeholder || "Name"}
                type="text"
                name="name"
                value={inputs.name || ""}
                onChange={handleChange}
                required
              />
            </li>
            <li className="half">
              <input
                placeholder={email_placeholder || "Email"}
                type="email"
                name="email"
                value={inputs.email || ""}
                onChange={handleChange}
                required
              />
            </li>
            <li>
              <textarea
                placeholder={message_placeholder || "Message"}
                name="message"
                value={inputs.message || ""}
                onChange={handleChange}
                required
              ></textarea>
            </li>
            <li className="button">
              <input type="submit" className="flat-button" value={send_btn || "Submit"} />
            </li>
          </ul>
        </form>
      </div>
    </section>
    );
  }