import React, {Component} from "react";

class Certifications extends Component {
  render() {
    if (this.props.sharedCerts && this.props.resumeBasicInfo) {
      var sectionName = this.props.resumeBasicInfo.section_name.certifications;
      var certifications = this.props.sharedCerts.badges.map(function (certs, i) {
        return (
          <li className="list-inline-item mx-3" key={i}>
            <img draggable="false" className="cert-image" 
            src={`images/${certs.image}`} alt="certification badge"></img>
          </li>
        );
      });
    }

    return (
      <section id="certifications">
          <div className="col-md-12 text-center">
            <div className="col-md-12">
              <h1 className="section-title">
                <span className="text-white">{sectionName}</span>
              </h1>
            </div>
            <ul className="list-inline mx-auto certification-badge">{certifications}</ul>
          </div>
      </section>
    );
  }
}

export default Certifications;